document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
      const $notification = $delete.parentNode
  
      $delete.addEventListener('click', () => {
        $notification.parentNode.removeChild($notification)
      })
    })
  }, 1000)
})